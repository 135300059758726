import * as React from 'react';

import {Card, Box, Typography, Tab, Tabs} from "@mui/material";

import Header from "../../../component/backend/Header/Header";
import CleaningSchedule from "./components/_cleaningSchedule";
import WorkSchedule from "./components/_workSchedule";
import Actions from "./components/_actions";
import Informations from "./components/_information";
import Layout from "./components/_layout";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Preview = () => {
    const [value, setValue] = React.useState(0);
    const [title, setTitle] = React.useState('Reinigungsplan');

    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
        setValue(newValue);
    };

    const clickHandle = (event: any): void => {
        const {target} = event;
        setTitle(target.textContent);
    }

    return (
        <Header title={title}>
            <Card sx={{boxShadow: 3, borderRadius: 4, padding: 3, marginTop: 5}} className="card--customs">
                <Box sx={{width: '100%', overflow: 'scroll'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
                              scrollButtons allowScrollButtonsMobile>
                            <Tab label="Board-Vorschau" {...a11yProps(0)} onClick={(event) => clickHandle(event)}/>
                            <Tab label="Schichtplan" {...a11yProps(1)} onClick={(event) => clickHandle(event)}/>
                            <Tab label="Aktionen" {...a11yProps(2)} onClick={(event) => clickHandle(event)}/>
                            <Tab label="Informationen" {...a11yProps(3)} onClick={(event) => clickHandle(event)}/>
                            <Tab label="Reinigungsplan" {...a11yProps(4)} onClick={(event) => clickHandle(event)}/>
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Layout/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <WorkSchedule/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Actions/>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Informations/>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <CleaningSchedule/>
                    </TabPanel>
                </Box>
            </Card>
        </Header>
    );
}

export default Preview;