import React from 'react';
import { HashRouter, Route, Routes } from "react-router-dom";

import App from "./feature/backend/App";
import CleaningSchedule from "./feature/backend/cleaningSchedule";
import NoContent from "./feature/noContent";

import './assets/scss/index.scss';
import Preview from "./feature/frontend/preview";
import WorkSchedule from "./feature/backend/workSchedule";
import Sales from "./feature/backend/sales";
import Information from "./feature/backend/information";

export default function Router() {
    return (
        <HashRouter basename="/">
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/preview" element={<Preview/>}/>
                <Route path="/cleaningSchedule" element={<CleaningSchedule/>}/>
                <Route path="/workSchedule" element={<WorkSchedule/>}/>
                <Route path="/sales" element={<Sales/>}/>
                <Route path="/information" element={<Information/>}/>
                <Route path="/no-content" element={<NoContent/>}/>
            </Routes>
        </HashRouter>
    );
}
