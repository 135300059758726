import React from "react";
import Header from "../../../component/backend/Header/Header";
import {
    Card,
    Grid,
} from "@mui/material";
import CleaningCard from "./components/Card";

const CleaningSchedule = () => {
    return (
        <Header title="REINIGUNGSPLAN">
            <Card sx={{boxShadow: 3, borderRadius: 4, padding: 3, marginTop: 5}} className="card--customs">
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <CleaningCard title={"Montag"} admin/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <CleaningCard title={"Dienstag"} admin/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <CleaningCard title={"Mittwoch"} admin/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <CleaningCard title={"Donnerstag"} admin/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <CleaningCard title={"Freitag"} admin/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <CleaningCard title={"Samstag"} admin/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <CleaningCard title={"Sonntag"} admin/>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <CleaningCard title={"Tägliche Aufgaben"} daily admin/>
                    </Grid>
                </Grid>
            </Card>
        </Header>
    )
}

export default CleaningSchedule;