import {Container, Grid, Button} from "@mui/material";
import TextCard from "../../component/backend/Card/TextCard";
import Header from "../../component/backend/Header/Header";

import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import GppMaybeRoundedIcon from '@mui/icons-material/GppMaybeRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import {Link} from "react-router-dom";

type CardContent = {
    title: string,
    icon: any,
    href: any
}

const Redirect = (props: {to: string}) => {
    return (
        <Link to={props.to} className="btn__link">
            <Button variant="contained" fullWidth sx={{ borderRadius: 5 }}>
                Ansehen
            </Button>
        </Link>
    )
}

const content: CardContent[] = [
    {
        title: "Reinigungsplan",
        icon: <ReceiptLongRoundedIcon className="fontSize--big"/>,
        href: <Redirect to={"/cleaningSchedule"} />
    },
    {
        title: "Schichtplan",
        icon: <AssignmentTurnedInRoundedIcon className="fontSize--big"/>,
        href: <Redirect to={"/workSchedule"} />
    },
    {
        title: "Aktionen",
        icon: <AssignmentIndRoundedIcon className="fontSize--big"/>,
        href: <Redirect to={"/sales"} />
    },
    {
        title: "Informationen",
        icon: <GppMaybeRoundedIcon className="fontSize--big"/>,
        href: <Redirect to={"/information"} />
    },
    {
        title: "Kein Inhalt",
        icon: <AdminPanelSettingsRoundedIcon className="fontSize--big"/>,
        href: <Redirect to={"/no-content"} />
    },
    {
        title: "Kein Inhalt",
        icon: <HelpOutlineRoundedIcon className="fontSize--big"/>,
        href: <Redirect to={"/no-content"} />
    }
];

const App = () => {

    return (
        <Header title={"Kunden im Fokus - Leistung im Blick!"}>
            <Container maxWidth="xl">
                <Grid container>
                    {content.map((card: CardContent) => {
                        return (
                            <Grid item xs={12} md={6} lg={6} xl={4}>
                                <TextCard title={card.title} icon={card.icon} href={card.href}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </Header>
    )
}

export default App;