import {Grid} from "@mui/material";
import {Item} from "../../../../component/frontend/SellingItem/Item";

const Actions = () => {
    return (
        <Grid container spacing={5}>
            <Grid item xl={3} md={6} sm={12}>
                <Item
                    name={"RedBull Sommer Edition"}
                    image={"https://www.konsum-leipzig.de/media/image/73/f8/20/23_RB_DE_OFF_Produktdatenbank_Juneberry_15x15-1.png"}
                />
            </Grid>
            <Grid item xl={3} md={6} sm={12}>
                <Item
                    name={"Blu 2.0 Vape Device"}
                    image={"https://www.blu.com/resources/_next/static/media/interactive_product.cd457a90.png?sq=mediumHigh&im=Resize,width=3840"}
                />
            </Grid>
            <Grid item xl={3} md={6} sm={12}>
                <Item
                    name={"Pfanner IceTea"}
                    image={"https://www.konsum-leipzig.de/media/image/c0/0e/b9/DV019_9006900011529_PERfsMQqolJQEzM5.png"}
                />
            </Grid>
            <Grid item xl={3} md={6} sm={12}>
                <Item
                    name={"Monster Energy Juice"}
                    image={"https://www.drinkenergy.ch/wp-content/uploads/2019/02/monster_energy_drink_juice_mango_loco_500ml_dose.png"}
                />
            </Grid>
        </Grid>
    )
}

export default Actions;