import {
    Card,
    CardContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Box,
    TableBody, Typography, Avatar, Button
} from "@mui/material";
import React from "react";

type TWorkCard = {
    name: string,
    time?: string
    color?: string
    backgroundColor?: string,
    admin?: boolean
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export const WorkCard = (props: TWorkCard) => {
    return (
        <Card sx={{boxShadow: 3, backgroundColor: props.backgroundColor ?? "#fff", color: props.color ?? "#000"}}>
            <CardContent>
                <Typography variant="subtitle2">{props.time ?? <></>}</Typography>
                <Typography variant="body2">{props.name}</Typography>
            </CardContent>
        </Card>
    )
}

export const WorkTable = (props: any) => {
    return (
        <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="table-head" align="center">{(props.admin) ?
                            <Button variant="contained">Hinzufügen</Button> : <></>}</TableCell>
                        <TableCell align="center" className="table-border table-head">Mo. 05.06.</TableCell>
                        {
                            (!props.toShow) ?
                                <>
                                    <TableCell align="center" className="table-border table-head">Di. 06.06.</TableCell>
                                    <TableCell align="center" className="table-border table-head">Mi. 07.06.</TableCell>
                                    <TableCell align="center" className="table-border table-head">Do. 08.06.</TableCell>
                                    <TableCell align="center" className="table-border table-head">Fr. 09.06.</TableCell>
                                    <TableCell align="center" className="table-border table-head">Sa. 10.06.</TableCell>
                                    <TableCell align="center" className="table-border table-head">So. 11.06.</TableCell>
                                </> : <></>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const Rows = (props: { toShow?: boolean }) => {
    return (
        <>
            <TableRow key={"row.name"} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell component="th" scope="row" align="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Avatar {...stringAvatar('James Neumann')} />
                    </Box>
                    <Typography variant="subtitle2">James Neumann</Typography>
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 am"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                {
                    (!props.toShow) ?
                        <>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                        </> : <></>
                }
            </TableRow>
            <TableRow key={"row.name"} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell component="th" scope="row" align="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Avatar {...stringAvatar('Max Mustermann')} />
                    </Box>
                    <Typography variant="subtitle2">Max Mustermann</Typography>
                </TableCell>
                <TableCell className="table-border">
                </TableCell>
                {
                    (!props.toShow) ?
                        <>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 am"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                            </TableCell>
                        </> : <></>
                }
            </TableRow>
            <TableRow key={"row.name"}>
                <TableCell component="th" scope="row" align="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Avatar {...stringAvatar('Peter Müller')} />
                    </Box>
                    <Typography variant="subtitle2">Peter Müller</Typography>
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 am"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                {
                    (!props.toShow) ?
                        <>
                            <TableCell className="table-border">
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 am"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                            <TableCell className="table-border">
                                <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 pm"} color={"#fff"}
                                          backgroundColor={"#1b68b5"}/>
                            </TableCell>
                        </> : <></>
                }
            </TableRow>
        </>
    )
}

const WorkSchedule = (props: { toShow?: boolean }) => {
    return (
        <Card>
            <CardContent>
                <WorkTable rows={<Rows toShow={props.toShow}/>} toShow={props.toShow}/>
            </CardContent>
        </Card>
    )
}

export default WorkSchedule;