import {Avatar, Card, Typography} from "@mui/material";
import Header from "../../component/backend/Header/Header";

import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';

const NoContent = () => {

    return (
        <Header title="Seite nicht gefunden..">
            <Card sx={{
                boxShadow: 3,
                borderRadius: 4,
                padding: 3,
                marginTop: 5,
                marginBottom: 11,
                marginLeft: 'auto',
                marginRight: 'auto'
            }} className="card--customs">
                <Avatar sx={{
                    width: 300,
                    height: 300,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    backgroundColor: 'rgba(247, 250, 255, 0)'
                }}>
                    <ReportGmailerrorredRoundedIcon className="fontSize__error"/>
                </Avatar>

                <Typography gutterBottom variant="h3" component="div" align="center">
                    Ein Fehler ist aufgetreten
                </Typography>
                <Typography gutterBottom variant="h5" component="div" align="center">
                    Diese Seite ist momentan noch im Aufbau und kann deshalb nicht dargestellt werden
                </Typography>
            </Card>
        </Header>
    )
}

export default NoContent;