import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import WorkSchedule from "./_workSchedule";
import CleaningCard from "../../../backend/cleaningSchedule/components/Card";
import {Item} from "../../../../component/frontend/SellingItem/Item";
import {NewsContent} from "./_information";

const Layout = () => {

    return (
        <Grid container spacing={0} sx={{p: 3, mt: 1, borderRadius: 5, backgroundColor: '#032b5a'}}>
            <Grid item lg={4} xs={12} sx={{m: '0 auto', border: "3px solid #fff", borderRadius: 5, p: 3}}>
                <Typography variant="h4" sx={{mb: 1}} className="fontSize__layout">Reinigungsplan:</Typography>

                <Box sx={{mr: 4, mb: 10, mt: 2}}>
                    <CleaningCard title={"Montag"}/>
                </Box>
                <Box sx={{mr: 4}}>
                    <CleaningCard title={"Dienstag"}/>
                </Box>
            </Grid>
            <Grid item lg={4} md={6} xs={12} sx={{m: '0 auto', border: "3px solid #fff", borderRadius: 5, p: 3}}>
                <Typography variant="h4" sx={{mb: 1}} className="fontSize__layout">Aktionen:</Typography>
                <Item
                    name={"RedBull Sommer Edition"}
                    image={"https://www.konsum-leipzig.de/media/image/73/f8/20/23_RB_DE_OFF_Produktdatenbank_Juneberry_15x15-1.png"}
                />
            </Grid>
            <Grid item lg={5} xs={12} sx={{m: '0 auto', border: "3px solid #fff", borderRadius: 5, p: 3, mt: 2}}>
                <Typography variant="h4" sx={{mb: 1}} className="fontSize__layout">Schichtplan:</Typography>
                <Card>
                    <CardContent>
                        <WorkSchedule toShow/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={6} xs={12} sx={{m: '0 auto', border: "3px solid #fff", borderRadius: 5, p: 3, mt: 2}}>
                <Typography variant="h4" sx={{mb: 1}} className="fontSize__layout">Newsletter:</Typography>
                <NewsContent/>
            </Grid>
        </Grid>
    )
}

export default Layout;