import Header from "../../../component/backend/Header/Header";
import {Card, CardContent, Grid, TextField, Typography, Button} from "@mui/material";
import * as React from "react";


const Information = () => {
    const [title, setTitle] = React.useState('Nachrichten Titel');
    const [message, setMessage] = React.useState('Deine Nachricht..');

    return (
        <Header title="Informationen">
            <Card sx={{boxShadow: 3, borderRadius: 4, padding: 3, marginTop: 5}} className="card--customs">
                <Grid container spacing={5}>
                    <Grid item xl={6} md={12}>
                        <TextField id="standard-basic" label="Titel" variant="standard" fullWidth
                                   onChange={(event) => setTitle(event.target.value)}/>

                        <TextField
                            id="standard-textarea"
                            label="Nachricht"
                            multiline
                            fullWidth
                            onChange={(event) => setMessage(event.target.value)}
                            sx={{mt: 3}}
                            variant="standard"
                        />

                        <Button variant="contained" sx={{mt: 3}}>Nachricht senden</Button>
                    </Grid>

                    <Grid item xl={6} md={12}>
                        <Card sx={{
                            color: "#000",
                            boxShadow: 10,
                            marginBottom: 2,
                            borderRadius: 2,
                            minHeight: 350,
                            padding: 5
                        }}>
                            <CardContent>
                                <Typography
                                    variant="h4">{(title.length <= 0) ? "Nachrichten Titel" : title}</Typography>
                                <Typography variant="body1" sx={{
                                    marginTop: "1.5rem",
                                    fontWeight: 'normal'
                                }}>{(message.length <= 0) ? "Deine Nachricht.." : message}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Card>
        </Header>
    )
}

export default Information;