import {Box, Grid, Typography} from "@mui/material";

type TProps = {
    title: string,
    children: any,
    centered?: any
}

const Header = (props: TProps) => {
    return (
        <Box mt={1} ml={5} mr={5}>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xl={8} xs={9}>
                        <Typography gutterBottom variant="h2" component="div" noWrap title={props.title}>
                            {props.title}
                        </Typography>
                    </Grid>

                    <Grid item xl={4} xs={3}>
                        <Typography variant="h2" component="div" align="right">
                            <img src="https://www.aral.de/apps/settings/wcm/designs/refresh/aral/images/aral-responsive.png" width="100rem" height="auto" alt="logo" />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {props.children}
        </Box>
    )
}

export default Header;