import Header from "../../../component/backend/Header/Header";
import {Avatar, Box, Card, TableCell, TableRow, Typography, CardActionArea} from "@mui/material";
import {WorkCard, WorkTable, stringAvatar} from "../../frontend/preview/components/_workSchedule";
import ModalComponent from "../../../component/backend/Modal/Modal";
import * as React from "react";

export type TUser = {
    username: string;
}

const Rows = (props: { setOpen: (open: boolean) => void, setUser: (user: TUser) => any }) => {
    return (
        <>
            <TableRow key={"row.name"} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <CardActionArea onClick={() => {
                    props.setOpen(true)
                    props.setUser({username: "James Neumann"})
                }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <TableCell component="th" scope="row" align="center">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Avatar {...stringAvatar('James Neumann')} />
                            </Box>
                            <Typography variant="subtitle2">James Neumann</Typography>
                        </TableCell>
                    </Box>
                </CardActionArea>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 am"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
            </TableRow>
            <TableRow key={"row.name"} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <CardActionArea onClick={() => {
                    props.setOpen(true)
                    props.setUser({username: "Max Mustermann"})
                }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <TableCell component="th" scope="row" align="center">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Avatar {...stringAvatar('Max Mustermann')} />
                            </Box>
                            <Typography variant="subtitle2">Max Mustermann</Typography>
                        </TableCell>
                    </Box>
                </CardActionArea>
                <TableCell className="table-border">
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 am"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                </TableCell>
            </TableRow>
            <TableRow key={"row.name"}>
                <CardActionArea onClick={() => {
                    props.setOpen(true)
                    props.setUser({username: "Peter Müller"})
                }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <TableCell component="th" scope="row" align="center">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Avatar {...stringAvatar('Peter Müller')} />
                            </Box>
                            <Typography variant="subtitle2">Peter Müller</Typography>
                        </TableCell>
                    </Box>
                </CardActionArea>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 am"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 am"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 2"} time={"8:00 am - 1:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
                <TableCell className="table-border">
                    <WorkCard name={"Bereich 1"} time={"8:00 am - 5:00 pm"} color={"#fff"} backgroundColor={"#1b68b5"}/>
                </TableCell>
            </TableRow>
        </>
    )
}

const WorkSchedule = () => {
    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState({username: ""});

    return (
        <Header title="Schichtplan">
            <Card sx={{boxShadow: 3, stateOpen: 4, padding: 3, marginTop: 5}} className="card--customs">
                <ModalComponent open={open} setOpen={(open) => setOpen(open)} user={user}/>

                <WorkTable
                    admin
                    rows={<Rows setOpen={(open) => setOpen(open)} setUser={(user) => setUser(user)}/>}
                />
            </Card>
        </Header>
    )
}

export default WorkSchedule;