import {Card, CardActions, CardContent, Typography, Grid, Avatar, Container} from "@mui/material";

type TProps = {
    title: string
    icon?: any,
    href: any
};

const TextCard = (props: TProps) => {
  return(
      <Container>
          <Avatar sx={{ width: 120, height: 120, marginLeft: 'auto', marginRight: 'auto', position: 'relative', top: 70, backgroundColor: 'rgba(247, 250, 255, .8)' }}>
              <Avatar sx={{ width: 100, height: 100, backgroundColor: '#032b5a' }}>
                  {props.icon}
              </Avatar>
          </Avatar>

          <Card sx={{ boxShadow: 3, borderRadius: 4, paddingTop: 8 }} className="card--customs">
              <CardContent>
                  <Grid
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                  >
                      <Typography gutterBottom variant="h4" component="div" align="center">
                          {props.title}
                      </Typography>
                  </Grid>
              </CardContent>
              <CardActions>
                  {props.href}
              </CardActions>
          </Card>
      </Container>
  )
};

export default TextCard;