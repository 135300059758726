import {Grid} from "@mui/material";
import CleaningCard from "../../../backend/cleaningSchedule/components/Card";

const CleaningSchedule = () => {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} md={6} lg={6} xl={4}>
                <CleaningCard title={"Montag"}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={4}>
                <CleaningCard title={"Dienstag"}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={4}>
                <CleaningCard title={"Mittwoch"}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={4}>
                <CleaningCard title={"Donnerstag"}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={4}>
                <CleaningCard title={"Freitag"}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={4}>
                <CleaningCard title={"Samstag"}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={4}>
                <CleaningCard title={"Sonntag"}/>
            </Grid>
            <Grid item xs={12} lg={8}>
                <CleaningCard title={"Tägliche Aufgaben"} daily/>
            </Grid>
        </Grid>
    )
}

export default CleaningSchedule;