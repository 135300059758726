import {Box, Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";

export const Item = (props: {image: string, name: string}) => {
    return(
        <Card>
            <CardActionArea>
                <Typography gutterBottom variant="h5" component="div" align={"center"} sx={{ backgroundColor: '#1b68b5;', color: '#fff', paddingBottom: 20, paddingTop: 5 }}>
                    {props.name}
                </Typography>
                <Box sx={{ position: 'relative', bottom: "1rem" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#1b68b5" fillOpacity="1" d="M0,96L60,90.7C120,85,240,75,360,64C480,53,600,43,720,53.3C840,64,960,96,1080,128C1200,160,1320,192,1380,208L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                    </svg>
                </Box>

                <CardContent sx={{ display: 'flex', justifyContent: 'center', height: "10rem", alignItems: 'center', position: 'relative', bottom: '8rem' }}>
                    <CardMedia
                        component="img"
                        image={props.image}
                        alt="product image"
                        sx={{ width: '75%' }}
                    />
                </CardContent>

                <Box sx={{ position: 'relative', bottom: 10, paddingLeft: 5, paddingRight: 5 }}>
                    <Box sx={{ float: 'left', marginTop: ".75rem" }}>
                        <Typography variant="subtitle1" color="text.secondary">
                            Aktionszeitraum:
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" sx={{ position: 'relative', bottom: '.5rem' }}>
                            01.06 - 20.06
                        </Typography>
                    </Box>
                    <Box sx={{ float: "right" }}>
                        <Typography variant="subtitle1" color="text.secondary" sx={{ color: '#c92424', position: 'relative', top: ".5rem", textAlign: "right" }}>
                            <del>9.99 &euro;</del>
                        </Typography>

                        <Typography  variant="h4" color="text.primary" sx={{ color: '#1b68b5', textDecoration: 'underline' }}>
                            9.99 &euro;
                        </Typography>
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    )
}