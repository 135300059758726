import {
    CardContent,
    Typography,
    Card,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Grid,
    TextField
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

type TProps = {
    title: string,
    daily?: any,
    admin?: boolean
}

const Form = (props: { admin: boolean | undefined }) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={(props.admin) ? <DeleteIcon sx={{ml: 1, mr: 1.25, color: '#b51b1b', padding: 1}}/> :
                    <Checkbox defaultChecked/>} label="Reinigungsaufgabe Nr. 1"/>
            <FormControlLabel
                control={(props.admin) ? <DeleteIcon sx={{ml: 1, mr: 1.25, color: '#b51b1b', padding: 1}}/> :
                    <Checkbox/>} label="Reinigungsaufgabe Nr. 2"/>
            <FormControlLabel
                control={(props.admin) ? <DeleteIcon sx={{ml: 1, mr: 1.25, color: '#b51b1b', padding: 1}}/> :
                    <Checkbox/>} label="Reinigungsaufgabe Nr. 3"/>
            <FormControlLabel
                control={(props.admin) ? <DeleteIcon sx={{ml: 1, mr: 1.25, color: '#b51b1b', padding: 1}}/> :
                    <Checkbox/>} label="Reinigungsaufgabe Nr. 4"/>
        </FormGroup>
    )
}

const HandleAdmin = (props: { admin: boolean | undefined }) => {
    if (!props.admin) return <></>;

    return <TextField id="standard-basic" label="Aufgabe hinzufügen" variant="standard" size={"small"}
                      sx={{width: '100%', mb: 2}}/>;
}

const CleaningCard = (props: TProps) => {

    return (
        <Card sx={{boxShadow: 3, borderRadius: 4}} className="card--customs">
            <CardContent>
                <Typography variant="h5" component="div" align="center" sx={{
                    backgroundColor: '#0064cc',
                    borderRadius: 4,
                    position: 'relative',
                    bottom: 15,
                    color: '#fff'
                }}>
                    {props.title}
                </Typography>
                <HandleAdmin admin={props.admin}/>

                {(!props.daily) ?
                    <Form admin={props.admin}/> :
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <Form admin={props.admin}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Form admin={props.admin}/>
                        </Grid>
                    </Grid>
                }
            </CardContent>
        </Card>
    )
}

export default CleaningCard;