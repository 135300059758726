import Header from "../../../component/backend/Header/Header";
import {Card, CardContent, Grid, TextField, Typography, Button} from "@mui/material";
import {Item} from "../../../component/frontend/SellingItem/Item";

import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';


const Sales = () => {
    return (
        <Header title="Aktionen">
            <Card sx={{boxShadow: 3, borderRadius: 4, padding: 3, marginTop: 5}} className="card--customs">

                <Card sx={{mb: 10, width: '85%', ml: 'auto', mr: 'auto'}}>
                    <CardContent>
                        <Typography
                            variant="h4"
                            component="div"
                            align="center"
                            sx={{
                                backgroundColor: '#0064cc',
                                borderRadius: 4,
                                position: 'relative',
                                bottom: 15,
                                color: '#fff',
                                padding: '.5rem',
                                mb: 4
                            }}>
                            Aktion hinzufügen
                        </Typography>

                        <Grid container spacing={4}>
                            <Grid item lg={6} md={12}>
                                <TextField id="standard-basic" label="Artikelname" variant="standard" fullWidth/>
                            </Grid>
                            <Grid item lg={6} md={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="standard-number"
                                            label="Artikelpreis"
                                            fullWidth
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="standard-number"
                                            label="Aktionspreis"
                                            fullWidth
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                        <DatePicker label="Aktionsstart"/>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item lg={6} md={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                        <DatePicker label="Aktionsende"/>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained">Produktbild hochladen</Button>
                            </Grid>

                            <Button variant="contained" sx={{width: '50%', mt: 4, ml: 'auto', mr: 'auto'}}>Aktion
                                starten</Button>
                        </Grid>
                    </CardContent>
                </Card>


                <Typography variant="h4" component="div" sx={{mb: 4}}>
                    Bereits erstellte Aktionen:
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xl={3} md={6} sm={12}>
                        <Item
                            name={"RedBull Sommer Edition"}
                            image={"https://www.konsum-leipzig.de/media/image/73/f8/20/23_RB_DE_OFF_Produktdatenbank_Juneberry_15x15-1.png"}
                        />
                    </Grid>
                    <Grid item xl={3} md={6} sm={12}>
                        <Item
                            name={"Blu 2.0 Vape Device"}
                            image={"https://www.blu.com/resources/_next/static/media/interactive_product.cd457a90.png?sq=mediumHigh&im=Resize,width=3840"}
                        />
                    </Grid>
                    <Grid item xl={3} md={6} sm={12}>
                        <Item
                            name={"Pfanner IceTea"}
                            image={"https://www.konsum-leipzig.de/media/image/c0/0e/b9/DV019_9006900011529_PERfsMQqolJQEzM5.png"}
                        />
                    </Grid>
                    <Grid item xl={3} md={6} sm={12}>
                        <Item
                            name={"Monster Energy Juice"}
                            image={"https://www.drinkenergy.ch/wp-content/uploads/2019/02/monster_energy_drink_juice_mango_loco_500ml_dose.png"}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Header>
    )
}

export default Sales;