import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {TUser} from "../../../feature/backend/workSchedule";
import {Grid, FormControl, InputLabel, MenuItem, Select, Button} from "@mui/material";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

type TProps = {
    open: boolean;
    setOpen: (open: boolean) => any;
    user: TUser
}

const ModalComponent = (props: TProps) => {
    const handleClose = () => props.setOpen(false);

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" sx={{ color: "#000", mb: 3 }} align={"center"}>
                    {props.user.username}
                </Typography>

                <Grid container spacing={4}>
                    <Grid item lg={6} md={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Datum" />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <FormControl sx={{ width: '92%', mt: 1 }}>
                            <InputLabel id="demo-simple-select-label">Bereich</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={"Bereich"}
                                label="Bereich"
                                onChange={() => console.log("change")}
                            >
                                <MenuItem value={10}>Bereich 1</MenuItem>
                                <MenuItem value={20}>Bereich 2</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']}>
                                <TimePicker label="Schichtanfang" />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']}>
                                <TimePicker label="Schichtende" />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>

                    <Button variant="contained" sx={{ mt: 2, ml: 'auto', mr: 'auto', width: '50%' }}>Speichern</Button>
                </Grid>
            </Box>
        </Modal>
    )
}

export default ModalComponent;