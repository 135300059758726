import {Card, CardContent, Grid, Typography, CardActionArea} from "@mui/material";

const News = () => {
    return (
        <Card sx={{backgroundColor: "#1b68b5", color: "#fff", boxShadow: 10, marginBottom: 2, borderRadius: 2}}>
            <CardActionArea>
                <CardContent>
                    <Typography variant="subtitle2">Von Mitarbeiter für Mitarbeiter</Typography>
                    <Typography variant="body2">Das ist ein kleiner Einblick in die Nachricht..</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export const NewsContent = () => {
    return (
        <Card sx={{color: "#000", boxShadow: 10, marginBottom: 2, borderRadius: 2, minHeight: 350, padding: 5}}>
            <CardContent>
                <Typography variant="h2">Von Mitarbeiter für Mitarbeiter:</Typography>
                <Typography variant="body1" sx={{marginTop: "1.5rem", fontWeight: 'normal'}}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat,
                    sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                    gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                    consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                    clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</Typography>
            </CardContent>
        </Card>
    )
}

const Informations = () => {

    return (
        <Grid container spacing={3} sx={{marginTop: 1}}>
            <Grid item xl={3} md={5} sm={12}>
                <News/>
                <News/>
                <News/>
                <News/>
                <News/>
            </Grid>

            <Grid item xl={9} md={7} sm={12}>
                <NewsContent/>
            </Grid>
        </Grid>
    )
}

export default Informations;